import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IBookingDetail, IBookingDetailsSearchData, IFeatureBooking, IFloorDetail, IListUser, IPaymentHistory, IPopularPlaces, IPropertyDetail, IPropertyOwnerDetail, IPropertyRoom, IRooms, ISaveBankDetails, ISponsored, ISponsorsTemp, IUser, IViewBankDetails, IViewBankDetailsTemp, IViewBookingDetail } from '../../property/property';


@Injectable({
  providedIn: 'root'
})

export class PropertyService {

  private propertyIdSource = new BehaviorSubject<number>(0);
  propertyId$ = this.propertyIdSource.asObservable();

  private sideNavMenuButtonSource = new BehaviorSubject<boolean>(false);
  sideNavMenuButton$ = this.sideNavMenuButtonSource.asObservable();

  /* PROPERTY */
  private ownerDetailsUrl = environment.apiUrl + 'ownerDetails'
  private policiesUrl = environment.apiUrl + 'savepolices'
  private basicDetailsUrl = environment.apiUrl + 'saveBasicDetals'
  private propertyDetailsUrl = environment.apiUrl + 'propertyDetails'
  private deletePropertyUrl = environment.apiUrl + 'propertyDetails'
  private publishPropertyUrl = environment.apiUrl + 'publishProperty'
  private unPublishPropertyUrl = environment.apiUrl + 'unPublishProperty'
  private primaryManagerDetailsUrl = environment.apiUrl + 'saveprimarymanagerdetails'
  private propertyFacilitiesUrl = environment.apiUrl + 'savePropertyFacilities'
  private paymentFacilitiesUrl = environment.apiUrl + 'savePaymentFacilities'
  private roomsUrl = environment.apiUrl + 'getRoomDetails'
  private listPropertyUrl = environment.apiUrl + 'propertyDetails'
  private bookingDetailsUrl = environment.apiUrl + 'bookingDetails'
  private getSingleBookingDetailsUrl = environment.apiUrl + 'getBookingDetails'
  private getMultiBookingDetailsUrl = environment.apiUrl + 'getBookingDetails'
  private sponsoredUrl = environment.apiUrl + 'propertyDetails'
  private viewSponsorsDetailsUrl = environment.apiUrl + 'sponsorDetails'
  private addFloorUrl = environment.apiUrl + 'floorDetails'
  private deleteFloorUrl = environment.apiUrl + 'floorDetails'
  private deleteRoomUrl = environment.apiUrl + 'roomDetails'
  private floorDetailsUrl = environment.apiUrl + 'floorDetails'
  private popularPlacesUrl = environment.apiUrl + 'popularPlaces'
  private bookingActionUrl = environment.apiUrl + 'bookingActions'
  private listUserUrl = environment.apiUrl + 'users'
  private registrationUrl = environment.apiUrl + 'register'
  private saveRoomUrl = environment.apiUrl + 'roomDetails'
  private userNameForOwnerDetailsUrl = environment.apiUrl + 'users'
  private userNameFilteredUrl = environment.apiUrl + 'getFilteredOwners'
  private propertyNameForBookingDetailsUrl = environment.apiUrl + 'property'
  private propertyNameFilteredUrl = environment.apiUrl + 'getFilteredProperty'
  private propertyNameForSponsoredDetailsUrl = environment.apiUrl + 'property'
  private propertyFilteredUrl = environment.apiUrl + 'getProperty'
  private getAllRoomsUrl = environment.apiUrl + 'getRoomNumbersByFloorId'
  private getRoomDetailsUrl = environment.apiUrl + 'roomDetails'
  private bankDetailsUrl = environment.apiUrl + 'vendorBankDetail'
  private deleteBankDetailsUrl = environment.apiUrl + 'vendorBankDetail'
  private paymentHistoryUrl = environment.apiUrl + 'vendorBankDetail'
  private roomDetailsByPropertyIdUrl = environment.apiUrl + 'getAllRoomDetailsByPropertyId'

  /* PHOTO */
  private propertyPhotosUrl = environment.apiUrl + 'propertyPhotos';
  private propertyPhotosGuestUrl = environment.apiUrl + 'propertyPhotosForGuest';
  private getPrimaryPhotoIdUrl = environment.apiUrl + 'getPrimaryPhotoId';
  private getPrimaryPhotoDetailsUrl = environment.apiUrl + 'getPrimaryPhotoDetails';
  private getPrimaryPhotoDetailsForGuestUrl = environment.apiUrl + 'getPrimaryPhotoDetailsForGuest';
  private setPropertyPrimaryPhotosUrl = environment.apiUrl + 'setPrimaryPhoto';
  private savePrimaryPhotoPositionUrl = environment.apiUrl + 'savePrimaryPhotoPosition';
  private allRoomsForFloorUrl = environment.apiUrl + 'propertyDetails';


  constructor(private http: HttpClient) {
    //console.log(environment.apiUrl);
  }

  //Rooms

  getAllRoomDetails(property_id: number, floor_id: number): Observable<{ data: IRooms[] }> {
    return this.http.get<any>(this.getAllRoomsUrl + '/' + property_id + '/' + floor_id);
  }

  getRoomDetails(room_id: number): Observable<{ data: IPropertyRoom }> {
    return this.http.get<any>(this.getRoomDetailsUrl + '/' + room_id);
  }

  getRooms(property_id: number, floor_id: number): Observable<{ data: IPropertyRoom }> {
    return this.http.get<any>(this.roomsUrl + '/' + property_id + '/' + floor_id);
  }

  getAllRoomsForFloor(floor_id: number | null): Observable<{ data: IPropertyRoom[] }> {
    return this.http.get<any>(this.allRoomsForFloorUrl + '/' + floor_id);
  }

  saveRoom(roomDetails: IPropertyRoom): Observable<{ data: IPropertyRoom }> {
    return this.http.post<any>(this.saveRoomUrl, roomDetails);
  }

  updateRoom(room_id: number, roomDetails: IPropertyRoom): Observable<{ data: IPropertyRoom }> {
    return this.http.put<{ data: IPropertyRoom }>(this.saveRoomUrl + '/' + room_id, roomDetails);
  }

  // Property

  getPropertyDetails(property_id: number): Observable<{ data: IPropertyDetail }> {
    return this.http.get<any>(this.propertyDetailsUrl + '/' + property_id);
  }

  getAllRoomDetailsByPropertyId(property_id: number): Observable<{ data: IRooms[] }> {
    return this.http.get<any>(this.roomDetailsByPropertyIdUrl + '/' + property_id);
  }

  getAllPropertyDetails(property_id:number | null): Observable<{ data: IPropertyDetail[] }> {
    // return this.http.get<any>(this.listPropertyUrl + '/' + property_id );

    let url = this.listPropertyUrl ;
    if (property_id) {
      url += '/' + property_id
    }
 
    return this.http.get<any>(url);



  }

  deleteProperty(property_id: number): Observable<IFeatureBooking> {
    return this.http.delete<any>(this.deletePropertyUrl + '/' + property_id);
  }

  publishProperty(property_id: number): Observable<any> {
    return this.http.put<any>(this.publishPropertyUrl + '/' + property_id, []);
  }

  unPublishProperty(property_id: number): Observable<any> {
    return this.http.put<any>(this.unPublishPropertyUrl + '/' + property_id, []);
  }



  getSearchedProperty(searchProperty: string): Observable<{ data: IPropertyDetail[] }> {
    return this.http.get<any>(this.listPropertyUrl + '/' + searchProperty);
  }

  savePropertyDetails(property: IPropertyDetail): Observable<any> {
    return this.http.post<any>(this.propertyDetailsUrl, property);
  }
  saveOwnerDetails(property: IPropertyDetail): Observable<any> {
    return this.http.post<any>(this.ownerDetailsUrl, property);
  }
  // saveRooms(property: IPropertyRoom): Observable<any> {
  //   return this.http.post<any>(this.roomsUrl, property);
  // }


  // updateRooms(property_id: number, floor_id: number, roomsDetails: { data: IPropertyRoom[] }): Observable<{ data: IPropertyRoom }> {
  //   return this.http.put<{ data: IPropertyRoom }>(this.roomsUrl + '/' + property_id + '/' + floor_id, roomsDetails);
  // }
  updatePropertyDetails(property_id: number, property: IPropertyDetail): Observable<{ data: IPropertyDetail }> {
    return this.http.put<{ data: IPropertyDetail }>(this.propertyDetailsUrl + '/' + property_id, property);
  }

  updateOwnerDetails(property_id: number, ownerDetails: { data: IPropertyOwnerDetail[] }): Observable<{ data: IPropertyOwnerDetail }> {
    return this.http.put<{ data: IPropertyOwnerDetail }>(this.ownerDetailsUrl + '/' + property_id, ownerDetails);
  }

  savePolicies(policies: any): Observable<any> {
    return this.http.post<any>(this.policiesUrl, policies);
  }

  saveBasicDetails(basic_details: any): Observable<any> {
    return this.http.post<any>(this.basicDetailsUrl, basic_details);
  }

  savePrimaryManagerDetails(primary_manager_details: any): Observable<any> {
    return this.http.post<any>(this.primaryManagerDetailsUrl, primary_manager_details);
  }

  savePropertyFacilities(proprty_facilities: any): Observable<any> {
    return this.http.post<any>(this.propertyFacilitiesUrl, proprty_facilities);
  }

  savePaymentFacilities(payment_facilities: any): Observable<any> {
    return this.http.post<any>(this.paymentFacilitiesUrl, payment_facilities);
  }

  //User  
  getFilteredUser(searchText: string): Observable<IUser[]> {
    return this.http.get<any>(this.userNameFilteredUrl, { params: { slug: searchText } });
  }

  // getFilteredProperty(searchText: string): Observable<IUser[]> {
  //   return this.http.get<any>(this.userNameFilteredUrl, { params: { slug: searchText } });
  // }

  getUserForOwwnerDetails(): Observable<{ data: IUser[] }> {
    return this.http.get<any>(this.userNameForOwnerDetailsUrl);
  }

  saveRegistration(registration: IListUser): Observable<any> {
    return this.http.post<any>(this.registrationUrl, registration);
  }

  updateRegistration(user_id: number, registration: IListUser): Observable<{ data: IListUser }> {
    return this.http.put<{ data: IListUser }>(this.listUserUrl + '/' + user_id, registration);
  }

  getListUsers(): Observable<any> {
    return this.http.get<any>(this.listUserUrl);
  }

  getUsersDetails(user_id: number): Observable<{ data: IListUser }> {
    return this.http.get<any>(this.listUserUrl + '/' + user_id);
  }

  //Booking details
  getFilteredProperty(searchText: string): Observable<IPropertyDetail[]> {
    return this.http.get<any>(this.propertyNameFilteredUrl, { params: { slug: searchText } });
  }

  getPropertyForBookingDetails(propertyId: number): Observable<{ data: IBookingDetailsSearchData[] }> {
    return this.http.get<any>(this.propertyNameForBookingDetailsUrl + '/' + propertyId);
  }

  saveBookingDetails(booking_details: IBookingDetailsSearchData): Observable<any> {
    return this.http.post<any>(this.bookingDetailsUrl, booking_details);
  }

  saveBookingDetail(booking_details: IBookingDetail): Observable<any> {
    return this.http.post<any>(this.getSingleBookingDetailsUrl, booking_details);
  }

  updateBookingDetails(booking_id: number, params: IBookingDetail): Observable<{ data: IBookingDetail }> {
    return this.http.put<{ data: IBookingDetail }>(this.getSingleBookingDetailsUrl + '/' + booking_id, params);
  }

  getPaymentHistory(booking_id: number): Observable<{ data: IPaymentHistory[] }> {
    return this.http.get<any>(this.paymentHistoryUrl + '/' + booking_id);
  }
  // getListBookingDetails(booking_id: number): Observable<{ data: IViewBookingDetail[] }> {
  //   return this.http.get<any>(this.viewbookingDetailsUrl + '/' + booking_id);
  // }

  // getBookingDetail(): Observable<{ data: IViewBookingDetail[] }> {
  //   return this.http.get<any>(this.viewbookingDetailsUrl);
  // }

  getBookingsDetails(booking_id: number): Observable<{ data: IViewBookingDetail[] }> {

    return this.http.get<any>(this.getSingleBookingDetailsUrl);
  }
  searchBookingDetail(params: IBookingDetailsSearchData): Observable<{ data: IBookingDetail[] }> {

    return this.http.post<any>(this.bookingDetailsUrl, params);
  }


  getCheckIn(booking_details_id: number): Observable<{ data: IViewBookingDetail[] }> {
    return this.http.get<any>(this.bookingActionUrl + '/' + booking_details_id);
  }

  getCheckOut(booking_details_id: number): Observable<{ data: IViewBookingDetail[] }> {
    return this.http.get<any>(this.bookingActionUrl + '/' + booking_details_id);
  }

  getSingleBookingDetails(booking_id: number): Observable<{ data: IBookingDetail }> {
    return this.http.get<any>(this.getSingleBookingDetailsUrl + '/' + booking_id);
  }

  getMultiBookingDetails(): Observable<{ data: IViewBookingDetail[] }> {
    return this.http.get<any>(this.getMultiBookingDetailsUrl);
  }

  getAllBookingDetails(bookingSearchCriteria: IBookingDetailsSearchData): Observable<{ data: IBookingDetail[] }> {
    return this.http.get<any>(this.bookingDetailsUrl);
  }

  //Sponsor details
  getProperty(): Observable<{ data: ISponsored[] }> {
    return this.http.get<any>(this.propertyFilteredUrl);
  }

  getPropertyForSponsoredDetails(): Observable<{ data: ISponsored[] }> {
    return this.http.get<any>(this.propertyNameForSponsoredDetailsUrl);
  }

  saveSponsoredDetails(sponsored_details: ISponsored): Observable<any> {
    return this.http.post<any>(this.sponsoredUrl, sponsored_details);
  }

  getViewSponsoredDetail(): Observable<any> {
    return this.http.get<any>(this.viewSponsorsDetailsUrl);
  }

  saveSponsorDetails(params: ISponsored): Observable<ISponsorsTemp> {
    return this.http.post<any>(this.viewSponsorsDetailsUrl, params);
  }

  updateSponsorDetails(sponsor_id: number, params: ISponsored): Observable<ISponsorsTemp> {
    return this.http.put<ISponsorsTemp>(this.viewSponsorsDetailsUrl + '/' + sponsor_id, params);
  }

  getSponsorDetails(sponsor_id: number): Observable<ISponsorsTemp> {
    return this.http.get<any>(this.viewSponsorsDetailsUrl + '/' + sponsor_id);
  }

  getViewSponsoredDetails(sponsor_id: number): Observable<ISponsorsTemp> {
    return this.http.get<any>(this.viewSponsorsDetailsUrl + '/' + sponsor_id);
  }

  activeChange(sponsored_id: number): Observable<any> {
    return this.http.post<any>(this.policiesUrl, sponsored_id);
  }

  /** This is primarily used by admin pages to get the property id from its child to set the navigation links */
  setCurrentPropertyId(property_id: number) {
    this.propertyIdSource.next(property_id)
  }

  setToggleMenuStatus(status: boolean) {
    this.sideNavMenuButtonSource.next(status)
  }

  //Bank details
  saveBankDetails(params: ISaveBankDetails,): Observable<any> {
    return this.http.post<any>(this.bankDetailsUrl, params);
  }

  updateBankDetails(vendor_bank_detail_id: number, params: IViewBankDetails): Observable<{ data: IViewBankDetails }> {
    return this.http.put<{ data: IViewBankDetails }>(this.bankDetailsUrl + '/' + vendor_bank_detail_id, params);
  }

  getBankDetails(property_id: number): Observable<IViewBankDetailsTemp> {
    return this.http.get<any>(this.bankDetailsUrl + '/' + property_id);
  }

  getBankDetail(property_id: number): Observable<IViewBankDetailsTemp> {
    return this.http.get<any>(this.bankDetailsUrl + '/' + property_id);
  }

  deleteBankDetails(vendor_bank_detail_id: number): Observable<any> {
    return this.http.delete<any>(this.deleteBankDetailsUrl + '/' + vendor_bank_detail_id);
  }

  //Popular places
  savePopularPlace(popular_place: IPopularPlaces): Observable<any> {
    return this.http.post<any>(this.popularPlacesUrl, popular_place);
  }

  //Floors
  getFloorDetails(property_id: number): Observable<{ floorDetails: IFloorDetail[] }> {
    return this.http.get<any>(this.floorDetailsUrl + '/' + property_id);
  }

  addFloor(params: any): Observable<any> {
    return this.http.post<any>(this.addFloorUrl, params);
  }

  deleteFloor(params: number): Observable<any> {
    return this.http.delete<any>(this.deleteFloorUrl + '/' + params);
  }

  deleteRoom(params: number): Observable<any> {
    return this.http.delete<any>(this.deleteRoomUrl + '/' + params);
  }

  /*  Photos  */

  getPropertyPhotos(property_id: number): Observable<any> {

    // If property id passed, then other propertys or back end propertys are accessing the photos else the owner
    const propertyPhotosUrl = (property_id !== null) ? this.propertyPhotosUrl + '/' + property_id : this.propertyPhotosUrl;

    return this.http.get(propertyPhotosUrl);
  }

  getPropertyPhotosForGuest(property_id: string | null): Observable<any> {

    // If property id passed, then other propertys or back end propertys are accessing the photos else the owner
    const propertyPhotosGuestUrl = (property_id !== null) ? this.propertyPhotosGuestUrl + '/' + property_id : this.propertyPhotosGuestUrl;

    return this.http.get(propertyPhotosGuestUrl);
  }

  getPropertyPrimaryPhotoId(property_id: string | null): Observable<any> {
    // If property id passed, then other propertys or back end propertys are accessing the photos else the owner
    const getPrimaryPhotoUrl = (property_id !== null) ? this.getPrimaryPhotoIdUrl + '/' + property_id : this.getPrimaryPhotoIdUrl;

    return this.http.get(getPrimaryPhotoUrl);
  }

  getPropertyPrimaryPhotoDetails(property_id: string | null): Observable<any> {

    const getPrimaryPhotoDetailsUrl = (property_id !== null) ? this.getPrimaryPhotoDetailsUrl + '/' + property_id : this.getPrimaryPhotoDetailsUrl;

    return this.http.get(getPrimaryPhotoDetailsUrl);
  }

  getPropertyPrimaryPhotoDetailsForGuest(property_id: string | null): Observable<any> {

    const getPrimaryPhotoDetailsForGuestUrl = (property_id !== null) ? this.getPrimaryPhotoDetailsForGuestUrl + '/' + property_id : this.getPrimaryPhotoDetailsForGuestUrl;

    return this.http.get(getPrimaryPhotoDetailsForGuestUrl);
  }

  setPropertyPrimaryPhoto(params: any) {
    return this.http.post(this.setPropertyPrimaryPhotosUrl, params);
  }

  updatePropertyPhoto(data: any, property_id: string): Observable<any> {

    return this.http.post(this.propertyPhotosUrl, data, {
      reportProgress: true,
      observe: 'events'
    })

  }

  deletePhoto(photo_id: number) {
    return this.http.delete(this.propertyPhotosUrl + '/' + photo_id);
  }

  savePrimaryPhotoPosition(photo_id: string, position: number, view_property_id: string | null) {
    const params = {
      property_photo_id: photo_id,
      v_position: position,
      view_property_id: view_property_id
    }

    return this.http.post(this.savePrimaryPhotoPositionUrl, params);
  }

  // logoutUser(): Observable<any> {

  //   //return this.http.get(this.logoutServiceUrl);
  // }

  // logoutUserFromAllDevices(): Observable<any> {

  //   return this.http.get(this.logoutAllServiceUrl);
  // }

}
